// obtain plugin
var cc = initCookieConsent();

var cookieTableHeaders = [
    {col1: 'Name'},
    {col2: 'Domain'},
    {col3: 'Expiration'},
    {col4: 'Description'}
];

var blockEn = [
    {
        title: 'Cookie usage 📢',
        description: 'This website uses cookies to ensure the basic functionalities of the website and to enhance your online experience.'
    }, {
        title: 'Strictly necessary cookies',
        description: 'These cookies are essential for the proper functioning of the website. Without these cookies, the website would not work properly',
        toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
        },
        cookie_table: [
            {
                col1: 'django_language',
                col2: 'oko-print.com',
                col3: '1 year',
                col4: 'This cookie stores the language of the website.'
            },
            {
                col1: 'csrftoken',
                col2: 'oko-print.com',
                col3: 'Session',
                col4: 'This cookie is used to help protect the website against Cross-Site Request Forgery attacks. A CSRF cookie is a random secret value, to which other sites will not have access. This value is passed back to the site server when you submit any forms on the site, in order to verify the form submission came from an expected source.'
            },
            {
                col1: '_GRECAPTCHA',
                col2: 'google.com',
                col3: 'Session',
                col4: 'This cookie provides our forms with spam protection. reCAPTCHA sets a necessary cookie when executed for the purpose of providing its risk analysis.'
            }
        ],
    }, {
        title: 'More information',
        description: 'For any queries in relation to our policy on cookies, please <a class="cc-link" href="#contact">contact us</a>.',
    }
];

var blockHu = [
    {
        title: 'Sütik használata 📢',
        description: 'Ez a weboldal sütiket használ a weboldal alapvető működésének biztosításához és az online felhasználói élmény javításához.'
    }, {
        title: 'Elengedhetetlen sütik',
        description: 'Ezek a sütik elengedhetetlenek az oldal megfelelő működéséhez. Ezek nélkül a weboldal nem fog megfelelően működni.',
        toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
        },
        cookie_table: [
            {
                col1: 'django_language',
                col2: 'oko-print.com',
                col3: '1 év',
                col4: 'Ez a süti a weboldal megjelenítésének nyelvét tárolja el.'
            },
            {
                col1: 'csrftoken',
                col2: 'oko-print.com',
                col3: 'Session',
                col4: 'Ez a süti segít megvédeni a weboldalt a Cross-Site Request Forgery (CSRF) támadásokkal szemben. A CSRF süti egy véletlenszerű titkos érték, amelyhez más oldalak nem férhetnek hozzá. Ezt az értéket a rendszer visszaküldi a webszervernek amikor a felhasználó elküld egy űrlapot, azért, hogy megbizonyosodjon, hogy az űrlap a várt forrástól érkezett.'
            },
            {
                col1: '_GRECAPTCHA',
                col2: 'google.com',
                col3: 'Session',
                col4: 'Ez a süti spam elleni védelmet nyújt űrlapjainknak. A reCAPTCHA a futásakor beállít egy nélkülözhetetlen sütit a kockázatelemzés érdekében.'
            }
        ],
    }, {
        title: 'Több információ',
        description: 'További információkért a sütikkel kapcsolatban, kérjük <a class="cc-link" href="#contact">lépjen kapcsolatba velünk</a>.',
    }
];

var blockRo = [
    {
        title: 'Folosirea modulelor cookie 📢',
        description: 'Această pagină folosește module cookie pentru asigurarea funcționalităților de bază ale site-ului și pentru înbunătățirea experienței online.'
    }, {
        title: 'Cookie-uri strict necesare',
        description: 'Ezek a sütik elengedhetetlenek az oldal megfelelő működéséhez. Ezek nélkül a weboldal nem fog megfelelően működni.',
        description: 'Aceste module cookie sunt strict necesare pentru funcționarea corectă a site-ului. Fără aceste pagina de web nu va funcționa corect.',
        toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
        },
        cookie_table: [
            {
                col1: 'django_language',
                col2: 'oko-print.com',
                col3: '1 an',
                col4: 'Acest modul cookie setează limba site-ului.'
            },
            {
                col1: 'csrftoken',
                col2: 'oko-print.com',
                col3: 'Session',
                col4: 'Acest modul cookie ajută în protecția site-ului împotriva atacurilor Cross-Site Request Forgery (CSRF). Un cookie CSRF este o valoare secretă aleatorie, la care alte site-uri nu vor avea acces. Această valoare este transmisă înapot serverului site-ului atunci când trimiteți orice formular pe site, pentru a verifica dacă trimiterea formularului a venit dintr-o sursă așteptată.'
            },
            {
                col1: '_GRECAPTCHA',
                col2: 'google.com',
                col3: 'Session',
                col4: 'Acest cookie oferă formularelor noastre protecție împotriva spamului. reCAPTCHA setează un cookie necesar atunci când este executat în scopul furnizării analizei de risc.'
            }
        ],
    }, {
        title: 'Mai multe detalii',
        description: 'Pentru mai multe detalii în legătură cu modulele cookie, vă rugăm <a class="cc-link" href="#contact">să ne contactați</a>.',
    }
];

window.addEventListener('load', function(){
    cc.run({
        current_lang: languageCode,
        autoclear_cookies: true,                   // default: false
        page_scripts: true,                        // default: false

        languages: {
            'en': {
                consent_modal: {
                    title: 'We use cookies!',
                    description: 'This website uses essential cookies to ensure its proper operation. <button type="button" data-cc="c-settings" class="cc-link">Details</button>',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept',
                    reject_all_btn: 'Reject',
                    close_btn_label: 'Close',
                    cookie_table_headers: cookieTableHeaders,
                    blocks: blockEn
                }
            },
            'hu': {
                consent_modal: {
                    title: 'Sütiket használunk!',
                    description: 'Ez a weboldal elengedhetetlen sütiket használ a megfelelő működéshez. <button type="button" data-cc="c-settings" class="cc-link">Részletek</button>',
                    primary_btn: {
                        text: 'Elfogadom',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Elutasítom',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Süti beállítások',
                    save_settings_btn: 'Beállítások mentése',
                    accept_all_btn: 'Elfogadom',
                    reject_all_btn: 'Elutasítom',
                    close_btn_label: 'Bezárás',
                    cookie_table_headers: cookieTableHeaders,
                    blocks: blockHu
                }
            },
            'ro': {
                consent_modal: {
                    title: 'Utilizăm module cookie!',
                    description: 'Această pagină de web utilizează module cookie pentru funcționare corectă. <button type="button" data-cc="c-settings" class="cc-link">Detalii</button>',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Resping',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Setări cookie',
                    save_settings_btn: 'Salvează modificările',
                    accept_all_btn: 'Accept',
                    reject_all_btn: 'Resping',
                    close_btn_label: 'Închide',
                    cookie_table_headers: cookieTableHeaders,
                    blocks: blockRo
                }
            }
        }
    });
});
